<template>
  <div id="refund-handle" v-if="page_view_show">
    <van-nav-bar title="退款处理" left-arrow @click-left="goLastPage" :border="false" />
    <div class="refund-price">
      <p>退款管理: <span class="count"> {{refund_view_obj.refunds[refund_view_obj.refunds.length - 1].refund_fee}} 元 </span> </p>
      <!-- <p>服务费41.00 + 物流费0.00元</p> -->
    </div>
    <div class="worker-tip">
      <p>1. 一旦检测到线下交易，将永久拉黑</p>
      <p>2. 该退款中包含物流费用，物流费用不会产生服务信息费的扣点，若退款有疑问可直接和商家联系，务必确认清除再做决定</p>
      <p>3. 如果拒绝退款后，商家未处理，系统将自动取消退款</p>

    </div>
    <div class="handle">
      <button :class="select == item.state? 'selected': ''" v-for="(item,index) in allowRefund" :key="index" @click="selectState(item)">{{item.refund_cn}}</button>
    </div>
    <van-field v-model="worker_handle_reason" autosize label="原因" type="textarea" maxlength="100" placeholder="请填写不同意退款原因" show-word-limit v-if="selected_refund_state == 4" />
    <div class="fixed-bottom-button">
      <van-button :style="{borderRadius: '20rem'}" type="primary" block color="linear-gradient(to right, #ff6034, #ee0a24)" text="确认退款" @click="confirmRefund"></van-button>
    </div>
  </div>
  <Loading show :type=3 v-else />
</template>
<script>
export default {
  data() {
    return {
      page_view_show: false,
      refund_view_obj: {},
      select: 1,
      allowRefund: [
        {
          refund_cn: "我同意退款",
          state: 2,
        },
        {
          refund_cn: "我不同意退款",
          state: 4,
        },
      ],
      selected_refund_state: undefined,
      worker_handle_reason: "",
    };
  },
  created() {
    this.getRefundaInfo();
  },
  methods: {
    /** 获取退款详情信息
     *
     */
    getRefundaInfo() {
      this.$http
        .get(`/api/v2/worker/order/refund/${this.$route.params.orderId}`)
        .then((res) => {
          console.log("退款详情", res);
          this.refund_view_obj = res.data;
          this.page_view_show = true;
        });
    },
    /** 师傅选择退款确认选项
     *
     */
    selectState(item) {
      this.select = item.state;
      this.selected_refund_state = item.state;
      console.log(this.selected_refund_state);
    },
    /** 确认退款
     *
     */
    confirmRefund() {
      if (this.selected_refund_state) {
        if (
          (this.selected_refund_state == 4 && this.worker_handle_reason) ||
          this.selected_refund_state == 2
        ) {
          this.$http
            .put(
              `api/v2/worker/order/${
                this.refund_view_obj.refunds[
                  this.refund_view_obj.refunds.length - 1
                ].id
              }/refund`,
              {
                status: this.selected_refund_state,
                worker_handle_reason: this.worker_handle_reason,
              }
            )
            .then((res) => {
              this.$toast.success(res.data.message);
              this.goSubPage("order");
            })
            .catch((err) => {
              console.log(err);
              this.$toast.fail(err.error);
              this.goSubPage("order");
            });
        } else {
          this.$toast.fail("请填写不同意原因");
        }
      } else {
        this.$toast.fail("请选择是否同意");
      }
    },
  },
};
</script>

<style lang="less" scoped>
#refund-handle {
  .refund-price {
    background: #fff;
    padding: 2rem;
    p:first-child {
      font-size: @theme-font-size-xl;
      padding-bottom: 1rem;
      .count {
        color: red;
        margin-left: 1rem;
      }
    }
    p:nth-child(2) {
      font-size: @theme-font-size-m;
    }
  }
  .worker-tip {
    padding: 2rem;
    font-size: @theme-font-size-m;
    p {
      padding: 0.4rem 0;
      line-height: 2rem;
    }
  }
  .handle {
    padding: 2rem;
    display: flex;
    button {
      flex: 1;
      background: none;
      padding: 1.2rem 0;
      margin: 0 1rem;
      border: 1px solid #b4b3b4;
      font-size: @theme-font-size-l;
    }
    .selected {
      background: @themeColor;
      color: #fff;
      border: 1px solid @themeColor;
    }
  }
  .fixed-bottom-button {
    width: 90%;
    margin: 2rem auto;
  }
}
</style>